import React, { useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Panel from 'components/Base/Panel'
import Card from 'components/Base/Card'
import MediaCard from 'components/Base/MediaCard'
import { UGD } from '../person/index.zh'
import { isPc } from 'utils/utils'
import { Container, FooterContainer } from './index.module.less'

import { fetchUGDCourse, fetchUGDReport } from 'api/user'

const Recommend: React.FC = (params) => {
  const [reportList, setReportList] = useState<UGD[]>()
  const [courses, setCourses] = useState<UGD[]>()

  useEffect(() => {
    if (isPc()) {
      window.location.href = '/account/person.html'
    }

    // 研究报告/白皮书
    fetchUGDReport().then(
      (res) => {
        setReportList(res.data)
      },
      (e) => {
        console.log(e.response)
      },
    )
    // 视频课程
    fetchUGDCourse().then(
      (res) => {
        setCourses(res.data)
      },
      (e) => {
        console.log(e.response)
        window.location.href = '/'
      },
    )

    if (!isPc()) {
      document.body.className = document.body.className + 'person-mb-FFFFFF'
    }
  }, [])

  useEffect(() => {
    if (isPc()) {
      window.location.href = '/account/person.html'
    }
  }, [])

  return (
    <Layout headerType="black" footerClassName={FooterContainer}>
      <Seo title="为你推荐" description="" keywords="" saTitle=""></Seo>
      <main className={Container}>
        <Card title="研究报告/白皮书">
          {reportList?.map((item, index) => (
            <MediaCard
              thumb={item.mainCoverUrl}
              title={item.title}
              buttonText="下载"
              buttonIcon="download"
              titleLineSize={2}
              href={item.infoLink}
              key={index}
            />
          ))}
          <div className="more-button-group">
            <a href="https://www.sensorsdata.cn/school/library" className="more-button arrow" target={'_blank'}>
              查看更多
            </a>
          </div>
        </Card>
        <Card title="视频课程">
          {courses?.map((item, index) => (
            <MediaCard
              thumb={item.mainCoverUrl}
              title={item.title}
              buttonText="观看"
              buttonIcon="camera"
              titleLineSize={2}
              href={item.infoLink}
              key={index}
            />
          ))}
          <div className="more-button-group">
            <a href="https://www.sensorsdata.cn/market/overview.html" className="more-button arrow" target={'_blank'}>
              查看更多
            </a>
          </div>
        </Card>
      </main>
    </Layout>
  )
}

export default Recommend
